/*
export const _GLOBAL = {
  URLBACKEND: 'http://localhost:11340',
  URLBIOS: 'http://localhost:1351',
  PORTALURL: 'http://localhost:8080'
}
*/

export const _GLOBAL = {
  URLBACKEND: 'https://apieoffice.bpdp.or.id',
  URLBIOS: 'https://apibios.bpdp.or.id',
  PORTALURL: 'https://eofficev3.bpdp.or.id'
}
